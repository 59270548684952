import { Stack, TextField } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptQuizWord } from '../../api/DialogPromptApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';

interface DialogPlanLlmPromptQuizWordGeneratedProps {
    dialogPrompt?: DialogPromptQuizWord; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptQuizWordGenerated: React.FC<DialogPlanLlmPromptQuizWordGeneratedProps> = ({ dialogPrompt, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [wrongWord1, setWrongWord1] = React.useState("");
    const [wrongWord2, setWrongWord2] = React.useState("");
    const [wrongWord3, setWrongWord3] = React.useState("");
    const [wrongWord4, setWrongWord4] = React.useState("");
    const [wrongWord5, setWrongWord5] = React.useState("");


    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPromptQuizWord) {
        setWrongWord1(dialogPrompt.wrong_word_1 ? dialogPrompt.wrong_word_1 : "")
        setWrongWord2(dialogPrompt.wrong_word_2 ? dialogPrompt.wrong_word_2 : "")
        setWrongWord3(dialogPrompt.wrong_word_3 ? dialogPrompt.wrong_word_3 : "")
        setWrongWord4(dialogPrompt.wrong_word_4 ? dialogPrompt.wrong_word_4 : "")
        setWrongWord5(dialogPrompt.wrong_word_5 ? dialogPrompt.wrong_word_5 : "")
    }

    // update dialog prompt
    const handleWrongWord1Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord1(e.target.value); if (dialogPrompt) dialogPrompt.wrong_word_1 = e.target.value };
    const handleWrongWord2Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord2(e.target.value); if (dialogPrompt) dialogPrompt.wrong_word_2 = e.target.value };
    const handleWrongWord3Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord3(e.target.value); if (dialogPrompt) dialogPrompt.wrong_word_3 = e.target.value };
    const handleWrongWord4Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord4(e.target.value); if (dialogPrompt) dialogPrompt.wrong_word_4 = e.target.value };
    const handleWrongWord5Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord5(e.target.value); if (dialogPrompt) dialogPrompt.wrong_word_5 = e.target.value };

    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Incorrect Word 1"
                value={wrongWord1}
                onChange={handleWrongWord1Change}
                required
            />
            <TextField
                label="Incorrect Word 2"
                value={wrongWord2}
                onChange={handleWrongWord2Change}
            />
            <TextField
                label="Incorrect Word 3"
                value={wrongWord3}
                onChange={handleWrongWord3Change}
            />
            <TextField
                label="Incorrect Word 4"
                value={wrongWord4}
                onChange={handleWrongWord4Change}
            />
            <TextField
                label="Incorrect Word 5"
                value={wrongWord5}
                onChange={handleWrongWord5Change}
            />
        </Stack>
    </>);
};

export default DialogPlanLlmPromptQuizWordGenerated;