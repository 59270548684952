import React, { Dispatch, SetStateAction } from "react";

import { Box, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';

import { v4 as uuid } from "uuid";

import { useHistory } from "react-router-dom";
import { hexToRgbaColorString, scoreColor } from "../Score";
import { CustomSentenceApi, ICustomSentence } from "../api/CustomSentenceApi";
import { ISentenceScore } from "../api/ScoreApi";
import DeleteBlueIcon from '../asset/delete-blue.png';
import CheckboxBlackIcon from '../asset/icon-check-black.png';
import CheckboxWhiteIcon from '../asset/icon-check-white.png';
import ShareIcon from '../asset/icon-share-black.png';
import PlayBlackIcon from '../asset/play-black.png';
import PlayWhiteIcon from '../asset/play-white.png';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import ShareDialog from "./ShareDialog";

type RecorderListProps = {
    audio: string | null;
    selectedSentence: ICustomSentence;
};

export type RecorderAudio = {
    key: string;
    audio: string;
    grade: ISentenceScore | null;
    timeStamp: string;
};

export const RECORDING_LIST = 'list';
export const RECORDING_SCORE = 'score'

type SetRecordingArray = Dispatch<SetStateAction<RecorderAudio[]>>;

export default function RecordingList({ audio, selectedSentence }: RecorderListProps) {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const { recordingArray, isScoring, setRecordingArray, setScoring, deleteAudio, deleteAllAudio } = useRecordingList(audio, handleScoreClick);

    const history = useHistory();

    const [recordingToggle, setRecordingToggle] = React.useState<string | null>(RECORDING_LIST);

    const [selectedRecording, setSelectedRecording] = React.useState<RecorderAudio | null>(null);

    const handleRecordingToggleClick = (event: React.MouseEvent<HTMLElement>, newToggle: string | null) => {
        setRecordingToggle(newToggle);
    };

    React.useEffect(() => {
        deleteAllAudio();
        setRecordingToggle(RECORDING_LIST)
        setSelectedRecording(null)
    }, [selectedSentence])

    // Define the type for the refs object
    interface AudioRefs {
        [key: number]: HTMLAudioElement | null;
    }
    const audioRefs = React.useRef<AudioRefs>({});

    const playAudio = (index: number) => {
        const audioElement = audioRefs.current[index];
        if (audioElement instanceof HTMLAudioElement) {
            audioElement.play();
        }
    };

    function handleScoreClick(record: RecorderAudio) {
        scoreAudio(record, selectedSentence.id);
        setRecordingToggle(RECORDING_SCORE)
    }

    function scoreAudio(record: RecorderAudio, id: number) {

        setScoring(true)

        fetch(record.audio)
            .then(response => response.blob())
            .then(async blob => {
                const formData = new FormData();
                formData.append('audio', blob);

                CustomSentenceApi.getScore(id, formData)
                    .then(response => {
                        // check if the response was successful
                        if (response.status >= 300) {
                            throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                        }
                        return response.data;
                    })
                    .then(responseJson => {
                        record.grade = responseJson
                        setSelectedRecording(record)
                        setRecordingArray((prevState: RecorderAudio[]) => {
                            return [...prevState]
                        })
                    })
                    .catch(err => {
                        handleErrorMessage(err, setErrorMessage, history);
                    })
                    .then(response => setScoring(false));
            })
    };

    const selectedAudioRef = React.useRef<HTMLAudioElement | null>(null);

    const playSelectedAudio = () => {
        if (selectedAudioRef.current) {
            selectedAudioRef.current.play();
        }
    }

    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

    const handleOpenShareDialog = () => {
        setIsShareDialogOpen(true);
    };

    return (
        <Stack alignItems="center">
            {/* Encapsulate horizontal alignment */}
            <Box sx={{
                display: 'flex', justifyContent: 'space-between', // Adjusted for clarity
                alignItems: 'center', width: '100%'
            }}>
                {/* Invisible spacer to balance the layout */}
                <Box sx={{ flex: 1 }}></Box>

                {/* For centering, we wrap the ToggleButtonGroup in a Box that takes up the needed space but doesn't affect its centering */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <ToggleButtonGroup
                        value={recordingToggle}
                        exclusive
                        onChange={handleRecordingToggleClick}
                        aria-label="text alignment"
                        sx={{ pt: 2 }} // Adds padding to the top
                    >
                        <ToggleButton value={RECORDING_LIST} aria-label="Your Recordings" sx={{ minWidth: '192px' }}>
                            {recordingToggle == RECORDING_LIST ? (
                                <img src={PlayWhiteIcon} style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                            ) : (
                                <img src={PlayBlackIcon} style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                            )}
                            Your Recordings
                        </ToggleButton>
                        <ToggleButton value={RECORDING_SCORE} aria-label="Your Score" sx={{ minWidth: '192px' }}>
                            {recordingToggle == RECORDING_SCORE ? (
                                <img src={CheckboxWhiteIcon} style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                            ) : (
                                <img src={CheckboxBlackIcon} style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                            )}
                            Your Score
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {/* Right Spacer: Specifically for "Share with Friends" to ensure it aligns to the right */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                }}>
                    {selectedRecording && selectedRecording.grade && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '24px',
                            pt: 2,
                        }}>
                            <Typography variant="h6" sx={{ marginRight: '16px' }}>
                                Share with friends
                            </Typography>
                            <IconButton
                                onClick={() => selectedRecording && selectedRecording.grade && handleOpenShareDialog()} // Open the share dialog
                            >
                                <img src={ShareIcon} alt="Share" style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                        </Box>
                    )}

                    {selectedRecording && selectedRecording.grade && (
                        <ShareDialog
                            isOpen={isShareDialogOpen}
                            onClose={() => setIsShareDialogOpen(false)}
                            sentenceScoreId={selectedRecording.grade.id} />
                    )}
                    {/* <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
            <DialogContent>
              <Stack spacing={1}>
                <Typography variant="h4">Share with Frields</Typography>
                <Box sx={{ padding: 2 }}>You can share your progress your friends!</Box>
                <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <FacebookShareButton
                    url={shareUrl}
                    quote={'Listen to my chinese'}
                    hashtag={'#shihoueri'}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <RedditShareButton
                    url={shareUrl}
                    title={'Listen to my chinese'}
                  >
                    <RedditIcon size={40} round={true} />
                  </RedditShareButton>
                  <TumblrShareButton
                    url={shareUrl}
                    title={'Listen to my chinese'}
                    tags={['shihoueri', 'chinese', 'languagelearning']}
                  >
                    <TumblrIcon size={40} round={true} />
                  </TumblrShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={'Listen to my chinese'}
                    hashtags={['shihoueri', 'chinese', 'languagelearning']}
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>

                  <WeiboShareButton
                    url={shareUrl}
                    title={'Listen to my chinese'}
                  >
                    <WeiboIcon size={40} round={true} />
                  </WeiboShareButton>

                  <WhatsappShareButton
                    url={shareUrl}
                    title={'Listen to my chinese'}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>

                  <IconButton aria-label="Link" onClick={handleCopyShareUrlToClipboard}>
                    <img src={ShareLinkIcon} alt="Link" style={{ maxWidth: '42px', maxHeight: '42px' }} />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogContent>
            <IconButton aria-label="close" onClick={handleCloseShareDialog} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <img src={CloseIcon} alt="Close" style={{ maxWidth: '16px', maxHeight: '16px' }} />
            </IconButton>
          </Dialog> */}
                </Box>
            </Box>
            {recordingToggle == RECORDING_LIST ? (
                <Box
                    sx={{
                        display: 'flex', // Use flex to center the child Box
                        flexDirection: 'column', // Stack child elements vertically
                        justifyContent: 'flex-start', // Center horizontally
                        alignItems: 'center', // Center vertically
                        height: 'calc(100vh - 550px)', // Parent container takes full viewport height
                        width: '75%', // Parent container takes full viewport width
                        backgroundColor: '#F2F7FF', // Set the background color
                        borderRadius: '16px', // Set the border radius
                        mt: 2, // Adds padding to the top
                        overflowY: 'auto', // Enables vertical scrolling if content overflows
                    }}
                >
                    {/* Reverse the array to display rows in reverse order */}
                    {[...recordingArray].map((record, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                mt: 2, // Margin top for spacing between rows
                                width: '80%'
                            }}
                        >
                            <audio ref={(el: HTMLAudioElement | null) => (audioRefs.current[index] = el)} src={record.audio} />
                            <IconButton onClick={() => playAudio(index)} sx={{ p: 0 }}>
                                <img src={PlayBlackIcon} alt="Play" style={{ maxWidth: '24px', maxHeight: '24px' }} />
                            </IconButton>
                            <Typography onClick={() => handleScoreClick(record)} style={{ flexGrow: 1, cursor: 'pointer', marginLeft: '16px' }}>
                                {record.timeStamp} - {selectedSentence.text}
                            </Typography>
                            <IconButton onClick={() => handleScoreClick(record)} sx={{ p: 0, ml: 2 }}>
                                <img src={CheckboxBlackIcon} alt="Grade" style={{ maxWidth: '24px', maxHeight: '24px' }} />
                            </IconButton>
                            <IconButton onClick={() => deleteAudio(record.key)} sx={{ p: 0, ml: 2 }}>
                                <img src={DeleteBlueIcon} alt="Delete" style={{ maxWidth: '24px', maxHeight: '24px' }} />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            ) : (isScoring ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pt: 4 }}>
                    <CircularProgress />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Calculating your score
                    </Typography>
                </Box>
            ) : (
                <TableContainer sx={{ overflowX: 'auto', p: 2 }}>
                    <Table aria-label="simple table" sx={{ minWidth: 650, border: 1, borderColor: 'rgba(224, 224, 224, 1)', margin: 'auto', width: 'auto' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderTopLeftRadius: '16px' }}>
                                    Word
                                </TableCell>
                                {(selectedRecording != null && selectedRecording.grade != null) && <>
                                    {selectedRecording.grade.score.map((value, index) =>
                                        <TableCell align="center" sx={{ py: 1 }}>
                                            {selectedRecording.grade && selectedRecording.grade.text[index]}
                                        </TableCell>
                                    )}
                                </>}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1 }}>
                                    Correct Pronunciation
                                </TableCell>
                                {(selectedRecording != null && selectedRecording.grade != null) && <>
                                    {selectedRecording.grade.score.map((value, index) =>
                                        <TableCell align="center" sx={{
                                            py: 1,
                                            backgroundColor: hexToRgbaColorString(scoreColor(100), 0.05),
                                            color: scoreColor(100)
                                        }}>
                                            {selectedRecording.grade && selectedRecording.grade.text_pinyin[index]}
                                        </TableCell>
                                    )}
                                </>}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{
                                    py: 1,
                                    display: 'flex', // Use Flexbox for alignment
                                    alignItems: 'center', // Vertically center the content
                                    gap: '8px', // Optional: Add space between the text and the image
                                }}>
                                    Your Pronunciation
                                    {/* The audio element */}
                                    {selectedRecording && <>
                                        <audio ref={selectedAudioRef} src={selectedRecording.audio} />
                                        <IconButton onClick={playSelectedAudio} sx={{ p: 0 }}>
                                            <img src={PlayBlackIcon} alt="Play" style={{ maxWidth: '24px', maxHeight: '24px' }} />
                                        </IconButton>
                                    </>}
                                </TableCell>
                                {(selectedRecording != null && selectedRecording.grade != null) && <>
                                    {selectedRecording.grade.score.map((value, index) =>
                                        <TableCell align="center" sx={{
                                            py: 1,
                                            backgroundColor: hexToRgbaColorString(scoreColor(selectedRecording.grade ? selectedRecording.grade.likely_score[index] : 100), 0.05),
                                            color: scoreColor(selectedRecording.grade ? selectedRecording.grade.likely_score[index] : 100)
                                        }}>
                                            {selectedRecording.grade && selectedRecording.grade.likely_pinyin[index]}
                                        </TableCell>
                                    )}
                                </>}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderBottomLeftRadius: '16px' }}>
                                    Grade
                                </TableCell>
                                {(selectedRecording != null && selectedRecording.grade != null) && <>
                                    {selectedRecording.grade.score.map((value, index) =>
                                        <TableCell align="center" sx={{
                                            py: 1,
                                            backgroundColor: hexToRgbaColorString(scoreColor(selectedRecording.grade ? selectedRecording.grade.likely_score[index] : 100), 0.05),
                                            color: scoreColor(selectedRecording.grade ? selectedRecording.grade.likely_score[index] : 100)
                                        }}>
                                            {selectedRecording.grade && Math.round(selectedRecording.grade.score[index])}
                                        </TableCell>
                                    )}
                                </>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ))}
        </Stack>
    );
}

export function useRecordingList(audio: string | null, handleScoreClick: (record: RecorderAudio) => void) {
    const [recordingArray, setRecordingArray] = React.useState<RecorderAudio[]>([]);
    const [isScoring, setScoring] = React.useState(false);

    function generateTimestamp(): string {
        const now = new Date();
        return now.getHours().toString().padStart(2, '0') + ':' +
            now.getMinutes().toString().padStart(2, '0') + ':' +
            now.getSeconds().toString().padStart(2, '0');
    }

    React.useEffect(() => {
        if (audio) {
            const nextRecorderAudio = { key: generateKey(), audio, grade: null, timeStamp: generateTimestamp() }
            setRecordingArray((prevState: RecorderAudio[]) => {
                return [nextRecorderAudio, ...prevState];
            });
            handleScoreClick(nextRecorderAudio)
        }
    }, [audio]);

    return {
        recordingArray: recordingArray,
        isScoring,
        setRecordingArray,
        setScoring,
        deleteAudio: (audioKey: string) => deleteAudio(audioKey, setRecordingArray),
        deleteAllAudio: () => deleteAllAudio(setRecordingArray)
    };
}

function generateKey() {
    return uuid();
}

function deleteAudio(audioKey: string, setRecordingArray: SetRecordingArray) {
    setRecordingArray((prevState) => {
        return prevState.filter((record) => record.key !== audioKey)
    });
}

function deleteAllAudio(setRecordingArray: SetRecordingArray) {
    setRecordingArray(() => {
        return []
    });
}
