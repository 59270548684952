import { Button, Stack } from '@mui/material';
import React from 'react';
import { CourseApi } from '../../api/CourseApi';
import { DialogApi } from '../../api/DialogApi';
import { DialogPromptStartClass } from '../../api/DialogPromptApi';
import { LessonApi } from '../../api/LessonApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { getAudioByText } from './DialogPromptComponent';

interface DialogPromptStartClassComponentProps {
    dialogPrompt: DialogPromptStartClass;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
    addDialog: (dialogId: number) => void;
}

const DialogPromptStartClassComponent: React.FC<DialogPromptStartClassComponentProps> =
    ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false, addDialog }) => {

        const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

        const [response, setResponse] = React.useState<string | null>(null);

        const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
        const [chatBubbleResponseDone, setChatBubbleResponseDone] = React.useState(false);

        const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);

        const handleYesButtonClick = async () => {
            setResponse(dialogPrompt.yes_response);

            try {
                const courseListResponse = await CourseApi.getAll();
                const courseList = courseListResponse.data;

                const lessonListResponse = await LessonApi.getAll(courseList[0].id);
                const lessonList = lessonListResponse.data;

                const dialogListResponse = await DialogApi.getAll(lessonList[0].id);
                const dialogList = dialogListResponse.data;

                addDialog(dialogList[0].id);
            } catch (err) {
                console.log("Error getting lesson" + err)
                handleErrorMessage(err, setErrorMessage, history);
            }
        }

        const handleNoButtonClick = () => {
            setResponse(dialogPrompt.no_response);
        }

        React.useEffect(() => {
            // clear promptInputComponent initially
            setPromptInputComponent(null);
            getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        }, []);

        React.useEffect(() => {
            if (chatBubbleDone) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}  >
                        <Button variant="contained" color="primary" onClick={() => handleYesButtonClick()} sx={{ ml: 4 }}>
                            {dialogPrompt.yes_button_text}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => handleNoButtonClick()} sx={{ ml: 4 }}>
                            {dialogPrompt.no_button_text}
                        </Button>
                    </Stack>
                )
            }
        }, [chatBubbleDone]);

        React.useEffect(() => {
            if (chatBubbleResponseDone) {
                setDialogPromptComplete(dialogPrompt.id);
            }
        }, [chatBubbleResponseDone])

        return (
            <Stack>
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    messageAudio={promptAudio ? promptAudio : null}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
                {response == null ? null : (
                    <ChatBubble
                        isTeacher={!dialogPrompt.is_teacher}
                        message={response}
                        messageAudio={null} // no audio for student
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleResponseDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
            </Stack>
        );
    };

export default DialogPromptStartClassComponent;
