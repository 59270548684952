import React, { Dispatch, SetStateAction } from "react";

import MicrophoneIcon from '../asset/icon-microphone-white.png';
import StopIcon from '../asset/icon-stop-red.png';

import { Button } from "@mui/material";
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import "./RecorderControl.css";



type Recorder = {
    recordingMinutes: number;
    recordingSeconds: number;
    initRecording: boolean;
    mediaStream: MediaStream | null;
    mediaRecorder: MediaRecorder | null;
    audio: string | null;
};

export type UseRecorder = {
    recorderState: Recorder;
    startRecording: () => void;
    cancelRecording: () => void;
    saveRecording: () => void;
};

type RecorderControlProps = {
    recorderState: Recorder;
    handlers: {
        startRecording: () => void;
        cancelRecording: () => void;
        saveRecording: () => void;
    };
};


type Interval = null | number | ReturnType<typeof setInterval>;
type SetRecorder = Dispatch<SetStateAction<Recorder>>;
type AudioTrack = MediaStreamTrack;
type MediaRecorderEvent = {
    data: Blob;
};


export default function RecorderControl({ recorderState, handlers }: RecorderControlProps) {
    const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
    const { startRecording, saveRecording, cancelRecording } = handlers;

    return (
        <div>
            {/* <div className="recorder-display">
        <div className="recording-time">
          {initRecording && <div className="recording-indicator"></div>}
          <span>{formatMinutes(recordingMinutes)}</span>
          <span>:</span>
          <span>{formatSeconds(recordingSeconds)}</span>
        </div>
        {initRecording && (
          <div className="cancel-button-container">
            <button className="cancel-button" title="Cancel recording" onClick={cancelRecording}>
              <CancelRoundedIcon />
            </button>
          </div>
        )}
      </div> */}
            <div>
                {initRecording ? (
                    <Button
                        title="Stop recording"
                        variant="contained"
                        disabled={recordingSeconds === 0}
                        onClick={saveRecording}
                        sx={{
                            minHeight: '48px',
                            minWidth: '192px',
                            backgroundColor: '#1E1E1E', // Set background color
                            color: '#F2F7FF', // Set text color 
                            '&:hover': {
                                backgroundColor: 'rgba(30, 30, 30, 0.8)' // Optional: darken on hover
                            },
                            '& .MuiSvgIcon-root': { color: '#F2F7FF' }, // Set icon color
                        }}
                    >
                        <img src={StopIcon} alt="Record" style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                        Stop Recording
                    </Button>
                ) : (
                    <Button title="Start recording" variant="contained" onClick={startRecording}
                        sx={{
                            minHeight: '48px',
                            minWidth: '192px',
                            backgroundColor: '#1E1E1E', // Set background color
                            color: '#F2F7FF', // Set text color
                            '&:hover': {
                                backgroundColor: 'rgba(30, 30, 30, 0.8)' // Optional: darken on hover
                            },
                            '& .MuiSvgIcon-root': { color: '#F2F7FF' }, // Set icon color
                        }}
                    >
                        <img src={MicrophoneIcon} alt="Record" style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                        Start Recording
                    </Button>
                )}
            </div>
        </div >
    );
}

function formatMinutes(minutes: number) {
    return minutes < 10 ? `0${minutes}` : `${minutes}`;
}

function formatSeconds(seconds: number) {
    return seconds < 10 ? `0${seconds}` : `${seconds}`;
}

const initialState: Recorder = {
    recordingMinutes: 0,
    recordingSeconds: 0,
    initRecording: false,
    mediaStream: null,
    mediaRecorder: null,
    audio: null
};

export function useRecorder() {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const [recorderState, setRecorderState] = React.useState<Recorder>(initialState);

    React.useEffect(() => {
        const MAX_RECORDER_TIME = 5;
        let recordingInterval: Interval = null;

        if (recorderState.initRecording)
            recordingInterval = setInterval(() => {
                setRecorderState((prevState: Recorder) => {
                    if (
                        prevState.recordingMinutes === MAX_RECORDER_TIME &&
                        prevState.recordingSeconds === 0
                    ) {
                        typeof recordingInterval === "number" && clearInterval(recordingInterval);
                        return prevState;
                    }

                    if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
                        return {
                            ...prevState,
                            recordingSeconds: prevState.recordingSeconds + 1,
                        };
                    else if (prevState.recordingSeconds === 59)
                        return {
                            ...prevState,
                            recordingMinutes: prevState.recordingMinutes + 1,
                            recordingSeconds: 0,
                        };
                    else return prevState;
                });
            }, 1000);
        else typeof recordingInterval === "number" && clearInterval(recordingInterval);

        return () => {
            typeof recordingInterval === "number" && clearInterval(recordingInterval);
        };
    });

    React.useEffect(() => {
        setRecorderState((prevState) => {
            if (prevState.mediaStream) {
                const newMediaRecorder = new MediaRecorder(prevState.mediaStream);
                return {
                    ...prevState,
                    mediaRecorder: newMediaRecorder,
                };
            }
            else return prevState;
        });
    }, [recorderState.mediaStream]);

    React.useEffect(() => {
        const recorder = recorderState.mediaRecorder;
        let chunks: Blob[] = [];

        if (recorder && recorder.state === "inactive") {
            recorder.start();

            recorder.ondataavailable = (e: MediaRecorderEvent) => {
                chunks.push(e.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: recorder.mimeType });
                chunks = [];

                setRecorderState((prevState: Recorder) => {
                    if (prevState.mediaRecorder)
                        return {
                            ...initialState,
                            audio: window.URL.createObjectURL(blob),
                        };
                    else return initialState;
                });
            };
        }

        return () => {
            if (recorder) recorder.stream.getAudioTracks().forEach((track: AudioTrack) => track.stop());
        };
    }, [recorderState.mediaRecorder]);



    async function startRecording(setRecorderState: SetRecorder) {

        try {
            const stream: MediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            setRecorderState((prevState) => {
                return {
                    ...prevState,
                    initRecording: true,
                    mediaStream: stream,
                };
            });
        } catch (err) {
            console.error(err)
            handleErrorMessage(err, setErrorMessage, history);
        }
    }

    function saveRecording(recorder: any) {
        if (recorder.state !== "inactive") recorder.stop();
    }

    return {
        recorderState,
        startRecording: () => startRecording(setRecorderState),
        cancelRecording: () => setRecorderState(initialState),
        saveRecording: () => saveRecording(recorderState.mediaRecorder),
    };
}
