import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptConversationSpeakOnly } from "../../api/DialogPlanLlmPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPlanLlmPromptConversationSpeakOnlyFormProps {
    dialogPlanLlmPromptId?: number;
    dialogPlanLlmPrompt?: DialogPlanLlmPromptConversationSpeakOnly;
    setDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;

    id: number;
    dialogPlanId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
}

export const DialogPlanLlmPromptConversationSpeakOnlyForm: React.FC<DialogPlanLlmPromptConversationSpeakOnlyFormProps> = ({
    dialogPlanLlmPromptId, dialogPlanLlmPrompt, setDialogPlanLlmPrompt, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating,
    id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [dialogPromptConversation, setDialogPromptConversation] = React.useState("");
    const handleDialogPromptConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDialogPromptConversation(e.target.value); };

    React.useEffect(() => {
        if (!dialogPlanLlmPromptId && dialogPlanLlmPrompt) {
            setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
        }
    }, [])
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !dialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    const dialogPlanLlmPrompt = response.data as DialogPlanLlmPromptConversationSpeakOnly;
                    setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [dialogPlanLlmPromptId])

    function setDialogPlanLlmPromptFields(dialogPlanLlmPrompt: DialogPlanLlmPromptConversationSpeakOnly) {
        setDialogPromptConversation(dialogPlanLlmPrompt.dialog_prompt_conversation ? dialogPlanLlmPrompt.dialog_prompt_conversation : "")
    }

    React.useEffect(() => {
        const newDialogPlanLlmPrompt = new DialogPlanLlmPromptConversationSpeakOnly(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse,
            noResponse,
            dialogPromptConversation as string | "ERROR",
        );
        if (handleLlmPromptChange) {
            handleLlmPromptChange(newDialogPlanLlmPrompt);
        }
        if (setDialogPlanLlmPrompt) {
            setDialogPlanLlmPrompt(newDialogPlanLlmPrompt);
        }
    }, [id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, dialogPromptConversation])

    return (
        <Stack spacing={4} sx={{
            width: dialogPlanLlmPrompt ? '100%' : '90%', // Conditionally set width
            margin: '0 auto', pt: 2, pb: 4
        }}>

            <TextField
                label="LLM Prompt for Conversation Dialog"
                value={dialogPromptConversation}
                onChange={handleDialogPromptConversationChange}
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "dialog_prompt_conversation")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
        </Stack>
    )
}