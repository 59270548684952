import '../style/global.css';

import { Box, Container, Divider, Grid, MenuItem, Select, Stack } from "@mui/material";
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChatApi, IChatLine } from '../api/ChatApi';
import ChatListContext from '../context/ChatListContext';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import SelectedChatContext from '../context/SelectedChatContext';
import ChatBubble from './ChatBubble';
import SendButtonTextField from './SendButtonTextField';

export default function ChatInterface() {

    const { selectedChat, setSelectedChat } = React.useContext(SelectedChatContext)
    const { chatList, setChatList } = React.useContext(ChatListContext)
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [sentence, setSentence] = React.useState<string | null>(null);

    const [hskLevel, setHskLevel] = React.useState(1);
    const [chatLineList, setChatLineList] = React.useState<IChatLine[]>([]);
    const chatBubbleContainerRef = React.useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const firstLine = "我是你的中文老师。你想问我什么？";
    const firstPinyin = ["wǒ", "shì", "nǐ", "de", "zhōng", "wén", "lǎo", "shī", "。", "nǐ", "xiǎng", "wèn", "wǒ", "shén", "me", "？"];

    React.useEffect(() => {
        const chatLine: IChatLine = {
            id: 0,
            chat_id: 0,
            role: 'assistant',
            line: firstLine,
            pinyin: firstPinyin
        };
        setChatLineList([chatLine])
    }, [])

    React.useEffect(() => {
        if (selectedChat.id > 0) {
            setHskLevel(selectedChat.hsk_level)
            ChatApi.getChatLineByChat(selectedChat.id)
                .then(response => {
                    setChatLineList(response.data)
                    // Handle the response
                    // console.log('User updated:', response.data);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        } else {
            const chatLine: IChatLine = {
                id: 0,
                chat_id: 0,
                role: 'assistant',
                line: firstLine,
                pinyin: firstPinyin
            };
            setChatLineList([chatLine])
        }
    }, [selectedChat])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sentence = event.target.value;
        setSentence(sentence);
    };

    const submitChatResponse = () => {
        if (sentence) {
            setIsLoading(true)
            let chat_id = 0
            if (chatLineList.length > 0) {
                chat_id = chatLineList[0].chat_id
            }
            const chatLine: IChatLine = {
                id: 0,
                chat_id: chat_id,
                role: 'user',
                line: sentence,
                pinyin: []
            };
            if (chat_id == 0) {
                // start new chat
                ChatApi.startNewConversationChatLine(chatLine, hskLevel)
                    .then(response => {
                        setChatLineList(response.data)
                        // Handle the response
                        // console.log('User updated:', response.data);
                    })
                    .catch(err => {
                        const newChatLine = {
                            id: -1, // Assuming you're generating an ID this way for simplicity
                            chat_id: -1, // Example chat_id, replace with actual data
                            role: 'assistant', // Example role, replace with actual data
                            line: 'This is a new chat line.', // Example line, replace with actual data
                            pinyin: []
                        };

                        if (err.response.status == 490) {
                            newChatLine.line = "Please validate your email before chatting. You can validate your email in your profile.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else if (err.response.status == 491) {
                            newChatLine.line = "You can only chat 30 sentences each day.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else if (err.response.status == 492) {
                            newChatLine.line = "This user is read only.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else {
                            handleErrorMessage(err, setErrorMessage, history);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } else {
                // Call the update function
                ChatApi.addConversationChatLine(chatLine, hskLevel)
                    .then(response => {
                        setChatLineList(response.data)
                        // Handle the response
                        // console.log('User updated:', response.data);
                    })
                    .catch(err => {
                        const newChatLine = {
                            id: -1, // Assuming you're generating an ID this way for simplicity
                            chat_id: -1, // Example chat_id, replace with actual data
                            role: 'assistant', // Example role, replace with actual data
                            line: 'This is a new chat line.', // Example line, replace with actual data
                            pinyin: []
                        };

                        if (err.response.status == 490) {
                            newChatLine.line = "Please validate your email before chatting. You can validate your email in your profile.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else if (err.response.status == 491) {
                            newChatLine.line = "You can only chat 30 sentences each day.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else if (err.response.status == 492) {
                            newChatLine.line = "This user is read only.";
                            setChatLineList((prevChatLineList) => [...prevChatLineList, newChatLine]);
                        } else {
                            handleErrorMessage(err, setErrorMessage, history);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    };

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            const timerId = setTimeout(() => {
                if (chatBubbleContainerRef.current) {
                    chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 50);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [chatLineList]); // Dependency array includes chatLineList to trigger effect when it changes

    const handleButtonClick = () => {
        if (sentence) {
            // save name
            submitChatResponse();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    return (
        <Container sx={{
            mx: 0, my: 0, p: 0, pr: 0, backgroundColor: '#F2F7FF',
            minHeight: 'calc(100vh - 98px)',
            display: 'flex', flexDirection: 'column', // Added flex properties
            // Attempt to override with higher specificity
            '&.MuiContainer-root': {
                paddingRight: 0,
            },
        }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}> {/* This Box will now grow to fill the container */}
                <Grid container justifyContent="center" sx={{ px: 2, flexGrow: 1 }}>
                    <Grid item
                        sx={{
                            marginTop: 2, paddingTop: 2, px: 2, paddingBottom: 2,
                            display: 'flex', flexDirection: 'column', // Important: Makes this Grid item a flex container
                            flexGrow: 1, // Make this Grid item grow
                            backgroundColor: '#FFFFFF',
                            borderRadius: '16px',
                            height: 'calc(100vh - 110px)', // Example with a calculated height
                        }}> {/* Adds padding to create a frame */}
                        {/* Flex container for content, making "Hello World" stack grow */}
                        <Stack sx={{
                            flexGrow: 1, // Ensure Stack fills the Grid item vertically
                            width: '100%', // Ensure Box fills the Grid item horizontally
                            borderRadius: '16px',
                            overflowY: 'auto', // Enables vertical scrolling
                        }}>
                            {chatLineList.map((chatLine, index) => (
                                <ChatBubble
                                    key={chatLine.id}
                                    isTeacher={chatLine.role != 'user'}
                                    message={chatLine.line}
                                    messageAudio={null}
                                    showAvatar={true}
                                    chatBubbleContainerRef={chatBubbleContainerRef}
                                    chatLine={chatLine}
                                    showChatButton={chatLine.role != 'user' && index > 0}
                                    showChatPinyin={hskLevel <= 3}
                                    isAnimated={index == chatLineList.length - 1}
                                />
                            ))}
                            <div ref={chatBubbleContainerRef} />
                        </Stack>
                        <Divider sx={{ width: '100%' }} />
                        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1, minHeight: '32px' }}>
                            <SendButtonTextField
                                value={sentence || ''}
                                onChange={handleInputChange}
                                onSubmit={handleButtonClick}
                                onKeyDown={handleKeyDown}
                                isLoading={isLoading}
                                sx={{ width: '80%', flexGrow: 1, marginRight: 4 }} // Directly control the width here
                                autoFocus
                            />
                            {/* Dropdown for HSK Level */}
                            <Select
                                labelId="hsk-level-label"
                                id="hsk-level-select"
                                value={hskLevel}
                                label="HSK Level"
                                onChange={(event) => setHskLevel(Number(event.target.value))}
                            >
                                {[1, 2, 3, 4, 5, 6].map((level) => (
                                    <MenuItem key={level} value={level}>
                                        HSK Level {level}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
