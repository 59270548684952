import { AppBar, Button, IconButton, Stack, Tab, Tabs, Typography, useTheme, Drawer } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import jwtDecode from 'jwt-decode';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import iconHome from './asset/icon-home.png';
import iconLesson from './asset/icon-lesson.png';
import iconLogout from './asset/icon-logout.png';
import iconProfile from './asset/icon-profile.png';
import iconPronounce from './asset/icon-pronounce-blue.png';
import iconSelfStudy from './asset/icon-self-study.png';
import ErrorMessageContext from './context/ErrorMessageContext';
import LoginLinkTextContext from './context/LoginLinkTextContext';

export function isJwtLoggedIn() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        try {
            const decodedToken: { exp: number } = jwtDecode(jwt);
            const currentTime = Date.now() / 1000;
            return currentTime < decodedToken.exp;
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return false;
        }
    }
    return false;
}

export default function Menu() {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const { errorMessage } = React.useContext(ErrorMessageContext);
    const { loginLinkText, setLoginLinkText } = React.useContext(LoginLinkTextContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const tabContainerRef = useRef<HTMLDivElement>(null);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleLoginClick = () => {
        localStorage.removeItem('jwt');
        setLoginLinkText('Login');
    };

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoginLinkText('Logout');
        } else {
            setLoginLinkText('Login');
        }
    }, [setLoginLinkText]);

    return (
        <AppBar position="static" elevation={0}
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: '1.5px solid rgba(82, 99, 125, 0.2)',
                padding: '16px 64px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
            
            {/* Mobile Menu Button */}
            <IconButton onClick={() => setMenuOpen(true)} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' } }}>
                <MenuIcon />
            </IconButton>

            {/* Tabs */}
            <Stack direction="row" alignItems="center" sx={{ flex: 1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                <div ref={tabContainerRef} style={{
                    display: 'flex',
                    overflowX: 'auto',
                    scrollBehavior: 'smooth',
                    whiteSpace: 'nowrap',
                    flex: 1
                }}>
                    <Tabs value={value} onChange={handleChange} sx={{ display: 'flex' }}>
                        {[ 
                            { label: "Home", icon: iconHome, to: "/" },
                            { label: "Lesson", icon: iconLesson, to: "/lesson" },
                            { label: "Pronunciation", icon: iconPronounce, to: "/sentence" },
                            { label: "Conversation", icon: iconSelfStudy, to: "/chat" },
                            { label: "Office Hours", icon: iconSelfStudy, to: "/office_hour" },
                            { label: "Profile", icon: iconProfile, to: "/registration" },
                        ].map((item, index) => (
                            <Tab key={index}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={item.icon} alt={item.label} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                        {item.label}
                                    </div>
                                }
                                component={Link}
                                to={item.to}
                                sx={{ color: theme.palette.primary.main, fontSize: '16px' }}
                            />
                        ))}
                    </Tabs>
                </div>
            </Stack>

            {/* Error Message */}
            {errorMessage && errorMessage.length > 0 && (
                <Typography variant="body1" sx={{ color: 'red', marginRight: '16px' }}>{errorMessage}</Typography>
            )}

            {/* Login/Logout Button */}
            <Link to="/login" style={{
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '16px',
                textDecoration: 'none',
            }} onClick={handleLoginClick}>
                {loginLinkText.toLowerCase() === "logout" ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={iconLogout} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        {loginLinkText}
                    </div>
                ) : (
                    <Button variant="outlined" sx={{
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        borderWidth: 2,
                        '&:hover': {
                            borderColor: theme.palette.primary.main,
                            backgroundColor: 'transparent',
                        },
                        minWidth: '128px',
                        height: '48px'
                    }}>
                        Sign In
                    </Button>
                )}
            </Link>

            {/* Mobile Drawer for Collapsible Menu */}
            <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
                <Stack direction="column" sx={{ width: 250, padding: 2 }}>
                    {[ 
                        { label: "Home", icon: iconHome, to: "/" },
                        { label: "Lesson", icon: iconLesson, to: "/lesson" },
                        { label: "Pronunciation", icon: iconPronounce, to: "/sentence" },
                        { label: "Conversation", icon: iconSelfStudy, to: "/chat" },
                        { label: "Office Hours", icon: iconSelfStudy, to: "/office_hour" },
                        { label: "Profile", icon: iconProfile, to: "/registration" },
                    ].map((item, index) => (
                        <Button key={index} component={Link} to={item.to} onClick={() => setMenuOpen(false)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                fontSize: '16px',
                                color: theme.palette.primary.main
                            }}>
                            <img src={item.icon} alt={item.label} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            {item.label}
                        </Button>
                    ))}
                </Stack>
            </Drawer>
        </AppBar>
    );
}
