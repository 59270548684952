import { Box, Button, Stack, TextField } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptConversationReadWithBlankSpeak } from '../../api/DialogPromptApi';
import { IDialogPromptConversation } from '../../api/DialogPromptConversationApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';

interface DialogPlanLlmPromptConversationReadWithBlankSpeakGeneratedProps {
    dialogPrompt?: DialogPromptConversationReadWithBlankSpeak; // if llmPrompt was generated
    dialogPromptConversation?: IDialogPromptConversation | null; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptConversationReadWithBlankSpeakGenerated: React.FC<DialogPlanLlmPromptConversationReadWithBlankSpeakGeneratedProps> = ({ dialogPrompt, dialogPromptConversation, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [dialogPromptConversationText, setDialogPromptConversationText] = React.useState("");
    const [sentenceBlankList, setSentenceBlankList] = React.useState<string[]>([]);
    const [sentenceBlankErrorList, setSentenceBlankErrorList] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPromptConversationReadWithBlankSpeak) {
        if (dialogPrompt.dialog_prompt_sentence_list) {
            const textList = dialogPrompt.dialog_prompt_sentence_list.map(sentence => sentence.sentence_text_alternate == undefined
                ? (sentence.sentence_text == undefined ? "" : sentence.sentence_text)
                : sentence.sentence_text_alternate
            );
            setSentenceBlankList(textList);
            setSentenceBlankErrorList(Array.from({ length: textList.length }, () => ''))
        } else {
            setSentenceBlankList([])
            setSentenceBlankErrorList([])
        }
    }
    React.useEffect(() => {
        if (dialogPromptConversation) {
            // setDialogPromptConversationText(dialogPromptConversation.dialog_prompt_sentence_list)
            setDialogPromptConversationText(dialogPromptConversation.dialog_prompt_sentence_list
                ? dialogPromptConversation.dialog_prompt_sentence_list
                    .map(item => item.sentence_text)
                    .join("\n") // Convert array to newline-separated string
                : ""
            )
            const textList = dialogPromptConversation.dialog_prompt_sentence_list.map(sentence => sentence.sentence_text_alternate == undefined
                ? (sentence.sentence_text == undefined ? "" : sentence.sentence_text)
                : sentence.sentence_text_alternate
            );
            setSentenceBlankList(textList);
            setSentenceBlankErrorList(Array.from({ length: textList.length }, () => ''))
        }
    }, [dialogPromptConversation])


    const handleDialogPromptConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConversationText = e.target.value;
        setDialogPromptConversationText(newConversationText);
        // Convert newline-separated string to array
        const newSentenceBlankList: string[] = newConversationText.split("\n").filter(line => line.trim() !== "");
        setSentenceBlankList(newSentenceBlankList)
        setSentenceBlankErrorList(Array.from({ length: newSentenceBlankList.length }, () => ''))

        if (dialogPromptConversation) {
            // Map each line to an IDialogPromptSentence object
            dialogPromptConversation.dialog_prompt_sentence_list = newSentenceBlankList.map((studentResponse, index) => ({
                id: 0, // Assuming `id` is generated by the backend
                sentence_id: 0, // Assuming this is set later or generated by the backend
                sentence_text: studentResponse.trim(), // Assign the trimmed response
                order_index: index + 1, // Incremental order based on line position
            }));
        }

    };


    // Function to handle changes in text inputs
    const handleSentenceBlankInputChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedValues = [...sentenceBlankList];
        updatedValues[index] = event.target.value;
        setSentenceBlankList(updatedValues);
        setSentenceBlankErrorList(Array.from({ length: updatedValues.length }, () => ''))
        if (dialogPrompt) {
            dialogPrompt.dialog_prompt_sentence_list[index].sentence_text_alternate = event.target.value;
        }
    };

    const handleSentenceBlankInputValidation = (index: number) => {
        if (dialogPromptConversation) {
            const originalSentence = dialogPromptConversation.dialog_prompt_sentence_list[index].sentence_text;
            const blankSentence = sentenceBlankList[index];

            setSentenceBlankErrorList((prevList) => {
                const updatedList = [...prevList]; // Create a copy of the previous list
                updatedList[index] = ""; // Set the i-th element to blank
                return updatedList; // Return the updated list
            });

            if (originalSentence == undefined) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Missing sentence';
                    return updatedList; // Return the updated list
                });
            }
            else if (originalSentence.length != blankSentence.length) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Not same length';
                    return updatedList; // Return the updated list
                });
            } else {
                for (let i = 0; i < originalSentence.length; i++) {
                    if (originalSentence[i] !== blankSentence[i] && blankSentence[i] !== "*") {
                        setSentenceBlankErrorList((prevList) => {
                            const updatedList = [...prevList]; // Create a copy of the previous list
                            updatedList[index] = 'Mismatch character / Not star';
                            return updatedList; // Return the updated list
                        });
                    }
                }
            }
        }
    }


    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Conversation Dialog"
                value={dialogPromptConversationText}
                onChange={handleDialogPromptConversationChange}
                multiline
                rows={5}
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <div>
                Replace any characters with star *
            </div>
            <div>
                <ul>
                    {sentenceBlankList.map((sentenceBlank, index) => (
                        <li key={index}>
                            {index % 2 === 0 ? 'A: ' : 'B: '}
                            <TextField
                                variant="outlined"
                                style={{ width: '80%' }}
                                value={sentenceBlankList[index] || ''}
                                onChange={(event) => handleSentenceBlankInputChange(index, event)}
                                onBlur={() => handleSentenceBlankInputValidation(index)}
                                error={Boolean(sentenceBlankErrorList[index])} // Set error prop based on error presence
                                helperText={sentenceBlankErrorList[index]} // Display error message
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </Stack>
    </>);
};

export default DialogPlanLlmPromptConversationReadWithBlankSpeakGenerated;